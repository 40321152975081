
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      hasLegacyExports: false,
      hasLegacyReports: false,
      items: [
        {
          icon: 'mdi-apps',
          roles: ['Admin', 'CustomerService'],
          title: 'Dashboard',
          to: '/',
        },
        {
          roles: ['Admin', 'AnalystAdmin', 'CustomerService'],
          title: 'Admin',
          items: [
            {
              icon: 'mdi-shield-crown',
              roles: ['Admin'],
              title: 'SMI Users',
              to: '/admin/smi-users',
            },
            {
              icon: 'mdi-account-group',
              roles: ['Admin', 'CustomerService'],
              title: 'Accounts',
              to: '/accounts',
            },
            {
              icon: 'mdi-account-multiple',
              roles: ['Admin', 'CustomerService'],
              title: 'Users',
              to: '/users',
            },
            {
              icon: 'mdi-table-account',
              roles: ['Admin', 'CustomerService'],
              title: 'Invitations',
              to: '/new-users/view-invitations',
            },
            {
              icon: 'mdi-playlist-remove',
              roles: ['Admin', 'AnalystAdmin'],
              title: 'Auto-Search Blacklist',
              to: '/admin/auto-search-blacklist',
            },
            {
              icon: 'mdi-file-cog',
              roles: ['Admin', 'CustomerService'],
              title: 'SOP',
              href: 'https://docs.google.com/document/d/19TKn9pqKr5e6lQUZfWGy5j2c4k2M5VTVAsfj-XWK85I',
              target: '_blank',
            },
          ],
        },
        {
          roles: [
            'Admin',
            'Analyst',
            'AnalystAdmin',
            'Client',
            'ClientAdmin',
            'CustomerService',
          ],
          title: 'Orders',
          items: [
            {
              icon: 'mdi-cart-plus',
              roles: [
                'Admin',
                'AnalystAdmin',
                'Client',
                'ClientAdmin',
                'CustomerService',
              ],
              title: 'Create New Order',
              color: 'primary',
              to: '/orders/start',
            },
            {
              icon: 'mdi-feature-search',
              roles: [
                'Admin',
                'Analyst',
                'AnalystAdmin',
                'Client',
                'ClientAdmin',
                'CustomerService',
              ],
              title: 'Deep Reports',
              to: '/deep-reports',
            },
            {
              icon: 'mdi-feature-search-outline',
              roles: [
                'Admin',
                'Analyst',
                'AnalystAdmin',
                'Client',
                'ClientAdmin',
                'CustomerService',
              ],
              title: 'Legacy Reports',
              to: '/legacy-orders/reports',
            },
            {
              icon: 'mdi-file-export',
              roles: [
                'Admin',
                'Analyst',
                'AnalystAdmin',
                'Client',
                'ClientAdmin',
                'CustomerService',
              ],
              title: 'Exports',
              to: '/exports',
            },
            {
              icon: 'mdi-file-export-outline',
              roles: [
                'Admin',
                'Analyst',
                'AnalystAdmin',
                'Client',
                'ClientAdmin',
                'CustomerService',
              ],
              title: 'Legacy Exports',
              to: '/legacy-orders/exports',
            },
            {
              icon: 'mdi-folder-multiple',
              roles: [
                'Admin',
                'Analyst',
                'AnalystAdmin',
                'Client',
                'ClientAdmin',
                'CustomerService',
              ],
              title: 'Project Orders',
              to: '/projects',
            },
          ],
        },
        {
          roles: [
            'Admin',
            'Analyst',
            'AnalystAdmin',
            'Client',
            'ClientAdmin',
            'CustomerService',
          ],
          title: 'Additional Services',
          items: [
            {
              icon: 'mdi-account-supervisor',
              roles: [
                'Admin',
                'Analyst',
                'AnalystAdmin',
                'Client',
                'ClientAdmin',
                'CustomerService',
              ],
              title: 'Expert Witnesses',
              to: '/expert-witnesses',
            },
          ],
        },
        {
          icon: 'mdi-file-cog',
          roles: ['Analyst', 'AnalystAdmin'],
          title: 'SOP',
          href: 'https://docs.google.com/document/d/19TKn9pqKr5e6lQUZfWGy5j2c4k2M5VTVAsfj-XWK85I',
          target: '_blank',
        },
        {
          icon: 'mdi-receipt-text',
          roles: [
            'Admin',
            'AnalystAdmin',
            'CustomerService',
          ],
          title: 'Invoices',
          to: '/invoices',
        },
        {
          href: 'https://buy.stripe.com/bIY6oWabpe9l6gE144',
          icon: 'mdi-account-credit-card',
          roles: [
            'Admin',
            'AnalystAdmin',
            'Client',
            'ClientAdmin',
            'CustomerService',
          ],
          target: '_blank',
          title: 'Payments',
        },
        {
          icon: 'mdi-account-box',
          roles: ['ClientAdmin'],
          title: 'Account',
          to: '/account',
        },
        {
          icon: 'mdi-account-multiple-plus',
          roles: ['Client', 'ClientAdmin'],
          title: 'Invite New Users',
          to: '/new-users/invite',
        },
        {
          icon: 'mdi-table-account',
          roles: ['ClientAdmin'],
          title: 'Invitations',
          to: '/new-users/view-invitations',
        },
      ],
      supportItems: [
        {
          doesntRequireAuth: true,
          title: 'Contact SMI',
          to: '/contact',
        },
        {
          doesntRequireAuth: true,
          title: 'FAQ',
          to: '/faq',
        },
      ],
      smiMenuOpen: false,
      supportMenuOpen: false,
      title: 'SMI Aware',
    }
  },
  async fetch () {
    await this.fetchLegacyOrderStatus()
  },
  computed: {
    ...mapState('main', {
      publicCdnUrl: 'publicCdnUrl',
    }),
    authUser () {
      return this.$auth?.user
    },
    drawer: {
      get () {
        return this.$store.state.navDrawer.expanded
      },
      set (val) {
        this.set(val)
      },
    },
    isLoggedIn () {
      return this.$store.state.auth.loggedIn
    },
    isSmi () {
      return this.isLoggedIn && ['Admin', 'AnalystAdmin', 'CustomerService', 'Analyst'].includes(this.authUser?.role?.name)
    },
    navLinks () {
      return this.prepareLinks(this.items)
    },
    supportMenuIcon () {
      return this.supportMenuOpen ? 'mdi-menu-up' : 'mdi-menu-down'
    },
    smiMenuIcon () {
      return this.smiMenuOpen ? 'mdi-menu-up' : 'mdi-menu-down'
    },
    supportNavLinks () {
      return this.supportItems.filter(x => x.doesntRequireAuth || this.isLoggedIn)
    },
  },
  watch: {
    async authUser () {
      await this.fetchLegacyOrderStatus()
    },
  },
  methods: {
    ...mapActions({
      unimpersonate: 'impersonate/unimpersonate',
      logout: 'logout',
    }),
    ...mapMutations({
      toggle: 'navDrawer/toggle',
      set: 'navDrawer/set',
    }),
    async fetchLegacyOrderStatus () {
      if (this.isLoggedIn) {
        const { data: hasLegacyExports } = await this.$axios.get('legacy/exports/count')
        const { data: hasLegacyReports } = await this.$axios.get('legacy/reports/count')

        this.hasLegacyExports = hasLegacyExports
        this.hasLegacyReports = hasLegacyReports
      } else {
        this.hasLegacyExports = false
        this.hasLegacyReports = false
      }
    },
    prepareLinks (items) {
      return items.reduce((links, item) => {
        if (!this.isLoggedIn) return links

        const hasPermission = !item.doesntRequireAuth
          ? (item.roles ?? []).includes(this.authUser?.role?.name)
          : true

        if (!hasPermission) return links

        if (item.title === 'Dashboard') {
          switch (this.authUser?.role?.name) {
            case 'Admin':
              item.to = '/admin/dashboard'
              break
            case 'CustomerService':
              item.to = '/customer-service/dashboard'
              break
            default:
              break
          }
        }

        if (item.to === '/legacy-orders/exports' && !this.hasLegacyExports) {
          return links
        }

        if (item.to === '/legacy-orders/reports' && !this.hasLegacyReports) {
          return links
        }

        return (item.items?.length ?? 0) > 0
          ? [
              ...links,
              {
                ...item,
                items: this.prepareLinks(item.items),
              },
            ]
          : [...links, item]
      }, [])
    },
  },
}
